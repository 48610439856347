define(['app', 'ePopup'], (app, ePopup) => {

  const fastTrackInfoBox = () => {
    const component = {};

    const GA_Category = 'Fast Track Engagement';
    const GA_Info_Icon_Action = 'Clicked Fast Pass | Info Icon';
    const GA_Learn_More_Action = 'Clicked Fast Pass | Learn More Text';
    const DS_Modal_Close_Event_Subtype = 'fast_track_popup_close';


    const _config = {
      selectors: {
        helpIcon: '.fastTrackInfoBox_helpIcon',
        learnMoreText: '.fastTrackInfoBox_learnMore',
        modalContents: '[data-fastTrackModal-content]'
      },
      channels: {
        toggleChange: 'fastTrackInfoBox/toggle'
      },
      classes: {
        fastTrackModalPopup: 'fastTrackModal_popup'
      }
    };

    const _init = (element) => {
      component.element = element;
      component.helpIcon = element.querySelector(component.config.selectors.helpIcon);
      component.learnMoreText = element.querySelector(component.config.selectors.learnMoreText);
      component.modalContents = element.querySelector(component.config.selectors.modalContents);

      app.subscribe(component.config.channels.toggleChange, component.toggleDisplay);

      component.addEventListeners();
      return component;
    };

    const _toggleDisplay = () => {
      component.element.classList.toggle('show');
    };

    const _addEventListeners = () => {
      if (component.helpIcon) {
        component.helpIcon.addEventListener('click', component.infoModalTriggered);
      }

      if (component.learnMoreText) {
        component.learnMoreText.addEventListener('click', component.infoModalTriggered);
      }
    };

    const _infoModalTriggered = (e) => {
      app.publish('tracking/record', GA_Category, component.learnMoreText ? GA_Learn_More_Action : GA_Info_Icon_Action);

      if (e) {
        e.preventDefault();
      }

      component.epopup = new ePopup(
        component.modalContents,
        component.config.classes.fastTrackModalPopup,
        false,
        true,
        undefined,
        component.trackModalClose
      );
    };

    const _trackModalClose = () => {
      app.publish('columbo/track', DS_Modal_Close_Event_Subtype, 'click');
    };

    component.config = _config;
    component.init = _init;
    component.toggleDisplay = _toggleDisplay;
    component.addEventListeners = _addEventListeners;
    component.infoModalTriggered = _infoModalTriggered;
    component.trackModalClose = _trackModalClose;

    return component;
  };

  return fastTrackInfoBox;
});
